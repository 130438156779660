import * as _ from 'lodash';
import {Component, OnDestroy, OnInit} from '@angular/core';

import {UserService} from '../../services/user.service';
import {MessagingService} from '../../services/messaging.service';

import {settings} from '../../settings';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html'
})
export class AccountComponent implements OnInit, OnDestroy {
    settings = settings;
    userInfo;
    user;
    invoices;
    constructor(public userService: UserService,
                private messagingService: MessagingService) {
        this.userInfo = userService.userInfo;
    }

    ngOnInit(): Subscription {
        return this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
            }
        });
    }

    ngOnDestroy(): void {
    }

    logout(): any{
        return this.userService.logout().subscribe();
    }

    subscriptionType(type): object {
        return this.userService.productNames[type];
    }

    dayDiff(): number {
        return this.userService.dayDiff();
    }

    clear(): Subscription {
        return this.userService.clearTasks().subscribe(res => {
            this.messagingService.setMessage('Your progress has been reset', true);
        }, (err) => {
            this.messagingService.setMessage(err, false);
        });
    }

}

