import {BehaviorSubject, from, Observable, of, throwError} from 'rxjs';
import {catchError, retry, tap} from 'rxjs/operators';

import * as _ from 'lodash';

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Location} from '@angular/common';

import {Router} from '@angular/router';

import {User} from '../models/user';
import {config} from '../config';

@Injectable()
export class UserService {
    userInfo = {
        beta: false,
        loggedIn: false,
        trialEnds: Date,
        validTrial: false,
        showTrial: true,
        basicSubscriber: false,
        premiumSubscriber: false,
        subscriber: {
            stripePay: false,
            googlePay: false,
            applePay: false
        },
        expired: {
            stripePay: false,
            googlePay: false,
            applePay: false
        },
        delinquent: {
            stripePay: false,
            googlePay: false,
            applePay: false
        },
        version: {}
    };

    productNames = {
        iBasic: 'Basic - iTunes $9.99/month',
        iPremium: 'Premium - iTunes $19.99/month',
        iYearly: 'Yearly Premium - iTunes  $199/year',
        gbasic: 'Basic - Google Pay $9.99/month',
        gpremium: 'Premium - Google Pay $19.99/month',
        gyearly: 'Yearly Premium - Google Pay $199/year',
        vid: 'Basic - Web $9.99/month',
        sft: 'Premium - Web $19.99/month',
        sftvid: 'Premium - Web $19.99/month',
        sftvidyearly: 'Yearly Premium - Web $199/year',
        price_1NH68i2geJctkXBk24C9XNRp: 'Yearly Premium - Web $199/year'
    };

    whereDoIStart = {
        recommendations: [] as any,
        showRecommendations: false,
        showStart: true
    };

    bsRecommendations = new BehaviorSubject<any[] | null>(null);
    bsShowRecommendations = new BehaviorSubject<boolean>(false);
    bsShowStart = new BehaviorSubject<boolean>(true);
    bsUser = new BehaviorSubject<null | User>(null);

    constructor(private location: Location,
                private http: HttpClient,
                private router: Router) {
    }

    dayDiff(): number {
        if (this.bsUser.value && _.has(this.bsUser.value, 'payment.trialEnds')) {
            const now = new Date().valueOf();
            const ends = Date.parse((this.bsUser.value as any).payment.trialEnds);
            const diff = (ends - now) / (1000 * 60 * 60 * 24);
            return Math.round(diff);
        }
        else {
            return 0;
        }
    }

    login(payload): Observable<any> {
        return this.http.post(config.server + 'login', payload, {withCredentials: true}).pipe(
            retry(3),
            tap(() => {
                this.setLoggedIn(true);
            }),
            catchError(error => this.handleError(error)));
    }

    register(payload): Observable<any> {
        return this.http.post(config.server + 'register', payload).pipe(
            tap(() => {
                setTimeout(() => {
                    return this.login(payload).subscribe(() => {
                        // window.location.href = '/modules';
                        return this.router.navigate(['/where-do-i-start']);
                    });
                }, 2000);
            }));
    }

    registerTherapist(payload): Observable<any> {
        return this.http.post(config.server + 'register', payload).pipe(
            retry(3),
            tap(() => {
                setTimeout(() => {
                    return this.login(payload).subscribe(() => {
                        this.router.navigate(['/therapist-welcome']);
                        window.location.href = '/therapist-welcome';
                    });
                }, 2000);
            }),
            catchError(error => this.handleError(error)));
    }

    inviteTherapist(payload): Observable<any> {
        return this.http.post(config.server + 'user/invite-therapist', payload, {withCredentials: true}).pipe(
            catchError(error => this.handleError(error)));
    }

    inviteClient(payload): Observable<any> {
        return this.http.post(config.server + 'user/invite-client', payload, {withCredentials: true}).pipe(
            catchError(error => this.handleError(error)));
    }

    getAccess(params): Observable<any> {
        return this.http.get(config.server + 'stats-access/' + params.userId + '/' + params.secret, {withCredentials: true}).pipe(
            catchError(error => this.handleError(error)));
    }

    connectTherapist(params): Observable<any> {
        return this.http.get(config.server + 'connect-therapist/' + params.userId + '/' + params.secret, {withCredentials: true}).pipe(
            catchError(error => this.handleError(error)));
    }

    getTherapists(): Observable<any> {
        return this.http.get(config.server + 'user/therapists', {withCredentials: true}).pipe(
            catchError(error => this.handleError(error)));
    }

    getClientList(): Observable<any> {
        return this.http.get(config.server + 'user-info/client-list', {withCredentials: true}).pipe(
            catchError(error => this.handleError(error)));
    }

    clearTasks(): Observable<any> {
        return this.http.delete(config.server + 'user/tasks', {withCredentials: true}).pipe(
            catchError(error => this.handleError(error)));
    }

    removeClient(id): Observable<any> {
        return this.http.delete(config.server + 'user/clients/' + id, {withCredentials: true}).pipe(
            catchError(error => this.handleError(error)));
    }

    removeTherapist(id): Observable<any> {
        return this.http.delete(config.server + 'user/therapist/' + id, {withCredentials: true}).pipe(
            catchError(error => this.handleError(error)));
    }

    addHomeworkNotify(payload): Observable<any> {
        return this.http.post(config.server + 'homework/notify-add', payload, {withCredentials: true}).pipe(
            catchError(error => this.handleError(error)));
    }

    removeHomeworkNotify(payload): Observable<any> {
        return this.http.post(config.server + 'homework/notify-remove', payload, {withCredentials: true}).pipe(
            catchError(error => this.handleError(error)));
    }

    logout(): Observable<any> {
        return this.http.get(config.server + 'logout', {withCredentials: true}).pipe(
            retry(3),
            tap(() => {
                this.bsUser.next(null);
                this.setLoggedIn(false);
                this.userInfo.basicSubscriber = false;
                this.userInfo.premiumSubscriber = false;
                return this.router.navigate(['/login']);
            }),
            catchError(error => this.handleError(error)));
    }

    getUserInfo(): Observable<any> {
        return this.http.get(config.server + 'userInfo', {withCredentials: true}).pipe(
            retry(3),
            tap(res => {
                const result = (res as User);
                this.bsUser.next((res as any));
                this.setLoggedIn(true);
                this.setValidTrial();
                this.setSubscriberTypes();
                this.setBasicSubscriber();
                this.setPremiumSubscriber();
                this.setSubscriber();
                this.bsUser.next((res as any));
                if (result.versions) {
                    this.userInfo.version = result.versions.web;
                }
                return of(this.bsUser.value);
            })
        );
    }

    updateMessages(payload): Observable<any> {
        return this.http.put(config.server + 'user/messages', payload, {withCredentials: true}).pipe(
            retry(3),
            catchError(error => this.handleError(error)));
    }

    saveHomework(payload): Observable<any> {
        return this.http.post(config.server + 'homework', payload, {withCredentials: true}).pipe(
            retry(3),
            catchError(error => this.handleError(error)));
    }

    saveHomeworkNotify(payload): Observable<any> {
        return this.http.post(config.server + 'homework-notify', payload, {withCredentials: true}).pipe(
            retry(3),
            catchError(error => this.handleError(error)));
    }

    saveHomeworkCompleted(payload): Observable<any> {
        return this.http.post(config.server + 'homework-completed', payload, {withCredentials: true}).pipe(
            retry(3),
            catchError(this.handleError));
    }

    saveHomeworkVideos(payload): Observable<any> {
        return this.http.post(config.server + 'homework-videos', payload, {withCredentials: true}).pipe(
            retry(3),
            catchError(error => this.handleError(error)));
    }

    updateRecommendations(payload): Observable<any> {
        return this.http.post(config.server + 'user/update-recommendations', payload, {withCredentials: true}).pipe(
            retry(3),
            catchError(error => this.handleError(error)));
    }

    updateRecommendationsTherapist(user, message): Observable<any> {
        const payload = {
            messageDate: new Date(),
            quizModules: message.quizModules,
            clientQuizMessage: true,
            therapists: user.sharedWith,
            connectId: user._id,
            name: user.local.name,
            email: user.local.email
        };

        return this.http.post(config.server + 'user/update-recommendations-therapist', payload, {withCredentials: true}).pipe(
            retry(3),
            catchError(error => this.handleError(error)));
    }

    invoices(): Observable<any> {
        return this.http.get(config.server + 'userInfo/invoices', {withCredentials: true}).pipe(
            retry(3),
            catchError(error => {
                return throwError(error);
            }));
    }

    getName(): string | null {
        if (this.bsUser.value) {
            if (this.bsUser.value.local && this.bsUser.value.local.name) {
                return this.bsUser.value.local.name;
            }
            else if (this.bsUser.value.google && this.bsUser.value.google.name) {
                return this.bsUser.value.google.name;
            }
            else if (this.bsUser.value.facebook && this.bsUser.value.facebook.name) {
                return this.bsUser.value.facebook.name;
            }
            return null;
        }
        return null;
    }

    getEmail(): string | null {
        if (this.bsUser.value) {
            if (this.bsUser.value.local && this.bsUser.value.local.email) {
                return this.bsUser.value.local.email;
            }
            else if (this.bsUser.value.google && this.bsUser.value.google.email) {
                return this.bsUser.value.google.email;
            }
            else if (this.bsUser.value.facebook && this.bsUser.value.facebook.email) {
                return this.bsUser.value.facebook.email;
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }

    isTherapist(): boolean {
        if (this.bsUser.value && (this.bsUser.value.userType === 'Therapist' || this.bsUser.value.userType === 'Staff' || this.bsUser.value.userType === 'Admin')) {
            return true;
        }
        else {
            return false;
        }
    }

    isAdmin(): boolean {
        if (this.bsUser.value && this.bsUser.value.userType === 'Admin') {
            return true;
        }
        else {
            return false;
        }
    }

    setSubscriber(): void {
        if (this.userInfo &&
            (this.userInfo.subscriber.stripePay === true ||
                this.userInfo.subscriber.applePay === true ||
                this.userInfo.subscriber.googlePay === true)
        ) {
            this.userInfo.showTrial = false;
        }
    }

    handleError(error: HttpErrorResponse): any {
        if (this.location && (this.location.path && this.location.path(false) === '/login' || this.location.path(false) === '/register' || this.location.path(false) === '/register-therapist')) {
            return throwError(error);
        }
        else {
            if (this.router && typeof error === 'object' && error.status === 401) {
                return from(this.router.navigate(['/login']));
            }
            else if (error.message && (error.message.match(new RegExp('ConnectException')) ||
                error.message.match(new RegExp('Connection timed out')) ||
                error.message.match(new RegExp('Could not connect')) ||
                error.message.match(new RegExp('0 Unknown Error')) ||
                error.message.match(new RegExp('Failed to connect'))
            )) {
                return this.router.navigate(['/no-internet']);
            }
            else {
                return throwError(error);
            }
        }
    }

    getHelp(formData): Observable<any> {
        return this.http.post(config.server + 'formhandler/contact', formData);
    }

    betaFeedback(formData): Observable<any> {
        return this.http.post(config.server + 'formhandler/beta-feedback', formData);
    }

    lostPass(formData): Observable<any> {
        return this.http.post(config.server + 'formhandler/lostpass', formData);
    }

    lostPassReset(formData): Observable<any> {
        return this.http.post(config.server + 'formhandler/lostpassreset2/', formData);
    }

    changePassword(formData): Observable<any> {
        return this.http.post(config.server + 'formhandler/change-password/', formData, {withCredentials: true});
    }

    deleteAccount(formData): Observable<any> {
        return this.http.post(config.server + 'delete-account/' + formData.userid, null, {withCredentials: true});
    }

    setLoggedIn(param): void {
        this.userInfo.loggedIn = param;
    }

    isIE(): boolean {
        const ua = window.navigator.userAgent;
        const ie = ua.indexOf('MSIE');
        const tr = ua.indexOf('Trident/');

        /*
            if (ua.length === 0) {
              return true;
            }
        */
        if (ie !== -1) {
            return true;
        }
        else if (tr !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    hideMessages(): boolean {
        if (this.bsUser.value?.messages && this.bsUser.value.messages.length > 0) {
            const unread = _.filter(this.bsUser.value.messages, (message: any) => {
                if (message && !message?.ack) {
                    return true;
                }
                else {
                    return false;
                }
            });
            if (unread.length > 0) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    private setValidTrial(): void {
        if (this.bsUser.value && _.has(this.bsUser.value, 'payment.trialEnds')) {
            const now = new Date();
            const ends = Date.parse((this.bsUser.value as any).payment.trialEnds);
            this.userInfo.validTrial = now.valueOf() < ends;
        }
    }

    private setSubscriberTypes(): void {
        if (this.bsUser.value && this.bsUser.value.payment && this.bsUser.value.payment.subscriptionId) {
            this.userInfo.subscriber.stripePay = true;
        }
        if (this.bsUser.value && this.bsUser.value.googlePay && this.bsUser.value.googlePay.productIdentifier) {
            this.userInfo.subscriber.googlePay = true;
        }
        if (this.bsUser.value && this.bsUser.value.applePay && this.bsUser.value.applePay.productIdentifier) {
            this.userInfo.subscriber.applePay = true;
        }
    }

    private setBasicSubscriber(): void {
        if (!this.bsUser.value) {
            this.userInfo.basicSubscriber = false;
        }
        else {
            if (this.bsUser.value.userType === 'Admin') {
                this.userInfo.basicSubscriber = true;
                this.userInfo.showTrial = false;
            }
            else if (this.bsUser.value.userType === 'Staff') {
                this.userInfo.basicSubscriber = true;
                this.userInfo.showTrial = false;
            }
            else if (this.bsUser.value.userType === 'Therapist') {
                this.userInfo.basicSubscriber = true;
                this.userInfo.showTrial = false;
            }
            else if (this.bsUser.value.payment && this.bsUser.value.payment.special) {
                this.userInfo.basicSubscriber = true;
            }
            else if (this.bsUser.value.beta) {
                this.userInfo.basicSubscriber = true;
            }
            else if (this.userInfo.validTrial) {
                this.userInfo.basicSubscriber = true;
            }

            if (this.bsUser.value.prepaid && new Date(this.bsUser.value.prepaid.subscriptionExpires) > new Date()) {
                this.userInfo.basicSubscriber = true;
                this.userInfo.showTrial = false;
            }
            else if (this.isValidApplePay()) {
                this.userInfo.showTrial = false;
                this.userInfo.basicSubscriber = true;
            }

            if (this.isValidStripePay()) {
                this.userInfo.showTrial = false;
                this.userInfo.basicSubscriber = true;
            }

            if (this.isValidGooglePay()) {
                this.userInfo.showTrial = false;
                this.userInfo.basicSubscriber = true;
            }
        }
    }

    private setPremiumSubscriber(): void {
        if (!this.bsUser.value) {
            this.userInfo.premiumSubscriber = false;
        }
        else {
            if (this.bsUser.value.userType === 'Admin') {
                this.userInfo.premiumSubscriber = true;
            }
            else if (this.bsUser.value.userType === 'Staff') {
                this.userInfo.premiumSubscriber = true;
            }
            else if (this.bsUser.value.userType === 'Therapist') {
                this.userInfo.premiumSubscriber = true;
            }
            else if (this.bsUser.value.beta === true) {
                this.userInfo.premiumSubscriber = true;
            }
            else if (this.userInfo.validTrial) {
                this.userInfo.premiumSubscriber = true;
            }
            else if (this.bsUser.value.prepaid && new Date(this.bsUser.value.prepaid.subscriptionExpires) > new Date()) {
                this.userInfo.premiumSubscriber = true;
            }
            else if (this.isValidStripePay('sftvid') || this.isValidStripePay('sft') || this.isValidStripePay('sftvidyearly') || this.isValidStripePay('price_1NH68i2geJctkXBk24C9XNRp')) {
                this.userInfo.premiumSubscriber = true;
            }
            else if (this.isValidApplePay('iPremium') || this.isValidApplePay('iYearly')) {
                this.userInfo.premiumSubscriber = true;
            }
            else if (this.isValidGooglePay('gpremium') || this.isValidApplePay('gpremiumyearly')) {
                this.userInfo.premiumSubscriber = true;
            }
        }
    }

    private isValidStripePay(type?): boolean {
        if (this.bsUser.value && this.bsUser.value.payment && this.bsUser.value.payment.subscriptionId) {
            if (new Date(this.bsUser.value.payment.subscriptionExpires) < new Date()) {
                this.userInfo.expired.stripePay = true;
            }

            if (this.bsUser.value.payment.delinquent) {
                this.userInfo.delinquent.stripePay = true;
                return false;
            }
            else if (!this.bsUser.value.payment.subscriptionExpires && this.bsUser.value.payment.subscriptionType) {
                return true;
            }
            else if (type && type === this.bsUser.value.payment.subscriptionType && new Date(this.bsUser.value.payment.subscriptionExpires) >= new Date()) {
                return true;
            }
            else if (!type && this.bsUser.value.payment.subscriptionType && new Date(this.bsUser.value.payment.subscriptionExpires) >= new Date()) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    private isValidApplePay(type?): boolean {
        if (this.bsUser.value && this.bsUser.value.applePay) {
            if (new Date(this.bsUser.value.applePay.subscriptionExpires) < new Date()) {
                this.userInfo.expired.applePay = true;
            }

            if (this.bsUser.value.applePay.delinquent) {
                this.userInfo.delinquent.applePay = true;
                return false;
            }
            else if (type && type === this.bsUser.value.applePay.productIdentifier && new Date(this.bsUser.value.applePay.subscriptionExpires) >= new Date()) {
                return true;
            }
            else if (!type && this.bsUser.value.applePay.productIdentifier && new Date(this.bsUser.value.applePay.subscriptionExpires) >= new Date()) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    private isValidGooglePay(type?): boolean {
        if (this.bsUser.value && this.bsUser.value.googlePay) {
            if (new Date(this.bsUser.value.googlePay.subscriptionExpires) < new Date()) {
                this.userInfo.expired.googlePay = true;
            }

            if (this.bsUser.value.googlePay.delinquent) {
                this.userInfo.delinquent.googlePay = true;
                return false;
            }
            else if (type && type === this.bsUser.value.googlePay.productIdentifier && new Date(this.bsUser.value.googlePay.subscriptionExpires) >= new Date()) {
                return true;
            }
            else if (!type && this.bsUser.value.googlePay.productIdentifier && new Date(this.bsUser.value.googlePay.subscriptionExpires) >= new Date()) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }


}
